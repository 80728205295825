@font-face {
  font-family: "Neutra";
  src: url("./assets/fonts/Neutra_Medium.woff");
}

@font-face {
  font-family: "InspireTWDC";
  src: url("./assets/fonts/InspireTWDC-Medium.woff");
}

* {
  box-sizing: border-box;
  outline: none;
  margin: 0;
  padding: 0;
}

body {
    /* Global variables */
    --white100: #f4f4f4;
    --gray100: #afccee;
    --gray400: #a2a2a2;
    --gray800: #4a4a4a;
    --blue500: #3372f0;
    --red500: #e10000;
    --red600: #c93b3b;
    --red800: #922525;
    --green700: #29605a;
    --gold500: #d0a555;

  margin: 0;
  font-family: "InspireTWDC", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("../public/images/Eurodisneyclub-Backgound.jpeg");

  color: var(--gray800);
  min-width: 320px;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "InspireTWDC", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  font-family: "InspireTWDC";
}

h1, h2, h3 {
  font-family: "Neutra", sans-serif;
}

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}