.container {
  width: 30px;
}

.flag_wrapper {
  height: 30px;
  min-width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: rgb(9 17 41 / 10%) 0px 2px 4px;
  border-radius: 40px;
  cursor: pointer;
  transition: all 0.2s ease;
  opacity: 0;
  margin-top: -30px;
}

.flag_wrapper:not(:last-child) { 
  margin-bottom: 3px;  
}

.flag_wrapper.active {
  display: flex;
  opacity: 1;
  margin-top: 0;
}

.flag_wrapper:hover {
  transform: scale(1.2);
}