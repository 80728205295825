.container {
  width: 100%;
  border-radius: 20px;
  border: grey solid 1px;
  overflow: hidden;
  margin: auto;
}

.slot-box {
  display: flex;
  justify-content: space-around;
  padding: 1vw;
  background-color: white;
  position: relative;
}

.slot-box:not(:last-child) {
  border-bottom: grey solid 1px;
}

.slot-label {
  margin-right: 3vw;
  padding-top: 0.5ch;
  width: 60vw;
}

.slot-input {
  line-height: 3ch;
  font-size: large;
  font-weight: bold;
  padding-left: 6px;
  margin: 0 10px;
  border: none;
  /* border: 1px solid #e9e9e9;
  border-radius: 4px; */
}

.container input[type="number"] {
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield;
}
.container input[type=number]::-webkit-inner-spin-button, 
.container input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
}

.input-wrapper {
  display: flex;
  align-items: center;
}

/* Buttons */
.button {
  align-items: center;
  appearance: none;
  border: 0;
  border-radius: 100px;
  /* box-shadow: rgba(45, 35, 66, .4) 0 2px 4px,rgba(45, 35, 66, .3) 0 7px 13px -3px,rgba(58, 65, 111, .5) 0 -3px 0 inset; */
  box-shadow: rgba(45, 35, 66, .20) 0 2px 4px,rgba(45, 35, 66, .15) 0 3px 7px -3px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono",monospace;
  height: 33px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 24px;
}

.button:hover {
  box-shadow: rgba(45, 35, 66, .20) 0 3px 6px, rgba(45, 35, 66, .15) 0 3px 7px -3px;
}

.button:active {
  box-shadow: rgba(45, 35, 66, .20) 0 2px 4px, rgba(45, 35, 66, .15) 0 3px 7px -3px, #dedede 0 3px 4px inset;
}

.disabled p.slot-label, .disabled div {
  cursor: default;
  opacity: 0.3;
  pointer-events: none;
}

.soldout-ticket {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: var(--red500);
  text-shadow: 0px 0px 1px var(--gray400);
}