.legalwrapper {
  max-width: 1140px;
  padding: 15px;
  color: var(--white100);
  text-align: center;
}

.legalwrapper p {
  margin-bottom: 10px;
  font-size: 14px;
}

.legalwrapper a {
  color: var(--gold500);
  cursor: pointer;
  text-decoration: underline;
}

.isLogged {
  color: var(--white100);
  text-align: center;
}

.home_btn {
  margin: 30px;
}

.isLogged a {
  color: var(--gold500);
  cursor: pointer;
  padding: 7px;
  border: 1px solid var(--gold500);
  border-radius: 7px;
}

.logout {
  margin: 30px;
}

.logout button {
  color: var(--red600);
  cursor: pointer;
  padding: 7px;
  border: 1px solid var(--red600);
  border-radius: 7px;
  font-size: 14px;
  text-transform: unset;
  font-weight: 500;
}