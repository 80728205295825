.container {
  display: flex;
  justify-content: space-between;
  padding: 7px 0
}

.container img {
  width: 150px;
  flex: 1
}

.side_element {
  width: 15%;
  padding: 20px 0 0 25px;
}