.edclub_title {
  text-align: center;
  color: var(--white100);
  margin-bottom: 25px;
  text-transform: uppercase;
}

.edclub_subtitle {
  text-align: center;
  color: var(--white100);
}
