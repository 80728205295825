.centeredContent {
  padding: 3vw;
  text-align: center;
}

.form {
  background-color: white;
  border-radius: 20px;
  padding: 1vw;
  margin: 40px 0;
}

.form h3 {
    font-size: 27px;
}

.consentLabel {
  margin-left: 10px;
  position: relative;
  bottom: 2px;
}

.error {
  color: red;
}

.success {
  color: #05841f;
}

.feedback-container {
  margin: 75px;
  width: 100%;
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 768px) {
  .form {
    margin: 50px;
    padding: 0 50px;
    width: 96%;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
  }
}
