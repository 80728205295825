.container {
  background-color: var(--green700);
  color: var(--white100);
  padding: 15px;
  font-size: 14px;
  margin-top: auto;
}

.container nav {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 150px;
}

.menu > li {
  flex: 1;
  text-align: center;
  padding-bottom: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu > li a {
  width: 100%;
  padding: 0 10px;
}

.btn_othersites {
  position: relative;
}

.btn_othersites span {
  text-transform: capitalize;
  font-weight: 500;
}

.submenu {
  position: absolute;
  bottom: 55px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 10px;
  border-radius: 5px;
  background-color: var(--green700);
  min-width: 200px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.submenu li {
  border-radius: 5px;
}

.submenu li:hover {
  background-color: var(--white100);
  color: var(--green700);
  cursor: pointer;
}

.submenu li a {
  padding: 15px;
  display: flex;
  font-size: 1rem;
  white-space: nowrap;
}

.socials {
  display: flex;
  margin: 15px 0 0;
}

.socials li {
  width: 55px;
  text-align: center;
}

.socials span {
  width: 15px;
  height: 15px;
  display: inline-block;
  color: var(--white100);
}

.container p {
  text-align: center;
  margin-top: 25px;
}

@media only screen and (min-width: 768px) {
  .menu {
    flex-direction: row;
    height: 100px;
    width: 90vw;
  }

  .menu > li:not(:last-child)::after {
    content: "";
    height: 40px;
    width: 1px;
    align-self: stretch;
    background-color: var(--gray100);
  }

  .socials {
    margin: 0;
  }
}
