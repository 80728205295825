.button {
  text-transform: uppercase;
  padding: 10px;
  border-radius: 5px;
  font-size: 13px;
  font-family: "InspireTWDC";
  letter-spacing: 0.05rem;
  font-weight: 900;
  border: 1px solid var(--gray400);
}

.iswhite {
  border: 1px solid var(--white100);
  color: var(--white100)
}

.islink {
  border: none;
  color: var(--gold500);
}