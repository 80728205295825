.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.container h1 {
  color: var(--gray800);
  font-size: 32px;
  text-transform: uppercase;
  margin-bottom: 25px;
  text-align: center;
}

.img_metas_wrapper {
  display: flex;
  flex-direction: column;
}

.img_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.metas_wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 30px;
}

.container h3 {
  color: var(--red800);
  text-transform: uppercase;
  text-align: center;
  font-size: 28px;
  margin: 15px;
}

.img_metas_wrapper img {
  width: 100%;
  max-width: 550px;
}

.metas_section {
  margin: 15px 0;
}

.metas_section_desc {
  margin-top: 15px;
  width: 75%;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .img_metas_wrapper {
    flex-direction: row-reverse;
  }
  .img_wrapper {
    width: 50%;
  }

  .metas_wrapper {
    flex: 1;
    flex-direction: column;
  }
}
