.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 25px 0;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: rgb(22 22 22 / 42%) 0px 1px 4px;
}

@media only screen and (min-width: 768px) {
  .container {
    width: 96%;
    max-width: 1100px;
  }
}