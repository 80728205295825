.container {
  color: var(--white100);
  text-align: center;
  margin-top: 50px;
}

.container h1 {
  margin: 15px auto;
}

.button {
  display: inline-block;
  background-color: #d0a555;
  color: #121212;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 40px auto;
  width: 50%;
}