.container {
  max-width: 1140px;
  margin-top: 15px;
  padding: 15px;
  color: var(--white100);
}

.container h2 {
  font-size: 30px;
  margin: 15px 0 10px;
}

.container h3 {
  margin: 28px 0 7px;
  font-size: 22px;
  text-transform: uppercase;
}

.container p {
  margin-bottom: 15px;
  font-size: 15px;
}