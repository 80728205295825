.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: rgb(22 22 22 / 42%) 0px 1px 4px;
  overflow: hidden;
  max-width: 550px;
  margin: 0 auto 35px;
}

.image_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image_wrapper img {
  width: 100%;
  max-width: 550px;
  height: 100%;
  object-fit: cover;
}

.infos_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 25px 0;
}

.infos_wrapper h3 {
  font-family: "Neutra", sans-serif;
  text-transform: uppercase;
  color: var(--blue500);
  font-size: 27px;
  margin: 10px 0 21px;
  text-align: center;
}

.infos_wrapper button {
  margin-top: 20px;
}

@media only screen and (min-width: 768px) {
  .container {
    flex-direction: row;
    height: 325px;
    max-width: 950px;
  }

  .image_wrapper {
    height: 100%;
    flex: 1.5;
  }
  .infos_wrapper {
    flex: 2;
  }
}