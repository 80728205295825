.subtitle {
  font-size: 15px;
}

.form {
  padding: 1vw;
  margin: 40px 0;
}

.subtitle, .asterisk {
  color: var(--white100);
}

.asterisk {
  font-size: 12px;
  margin-top: 10px;
}

.input {
  margin: 0.5rem 0;
  padding: 0.5rem;
  width: 100%;
  height: 2rem;
  border: none;
  border-bottom: 1px solid var(--white100);
  box-sizing: border-box;
  background-color: transparent;
  color: var(--white100);
}

.input::placeholder {
  color: var( --gray400);
}

.error {
  color: var(--red500);
}

@media screen and (min-width: 500px) {
  .container {
    padding: 25px;
  }
}

@media screen and (min-width: 768px) {
  .container {
    width: 67%;
  }
}