.message {
  color: #0f5132;
  padding: 20px 5px;
  width: 96%;
  max-width: 700px;
  background-color: #d1e7dd;
  text-align: center;
  border: 1px solid #badbcc;
  border-radius: 3px;
}

.caution {
  color: #510f0f;
  background-color: #fddede;
  border-color:#fbaaaa;
}

@media only screen and (min-width: 500px) {
  .message {
    padding: 20px;
    width: 80%;
  }
}