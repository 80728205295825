.container {
  padding: 25px;
  max-width: 950px;
  width: 100%;
}

.container h1 {
  text-align: center;
  margin-bottom: 25px;
  color: #f4f4f4;
  text-transform: uppercase;
}

.preview {
  text-align: center;
  font-size: 22px;
  text-transform: uppercase;
  color: white;
  margin-bottom: 15px;
}

.container .noevents p {
  text-align: center;
  color: #f4f4f4;
}

.noevents {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
